<template>
    <BaseView>
        <template v-slot:header>
            <div class="header" @click="$router.push(`/bearbeiten/${vorgangsId}`)">
                <div class="left">
                    <svg width="42" height="34">
                        <rect x="1" y="0"  width="40" height="10" :style="{ fill: (versandOption && versandOption.indexOf('G') >= 0) ? 'rgb(0,128,0)'   : 'rgb(128,128,128)' }" />
                        <rect x="1" y="12" width="40" height="10" :style="{ fill: (versandOption && versandOption.indexOf('Y') >= 0) ? 'rgb(255,255,0)' : 'rgb(128,128,128)' }" />
                        <rect x="1" y="24" width="40" height="10" :style="{ fill: (versandOption && versandOption.indexOf('R') >= 0) ? 'rgb(255,0,0)'   : 'rgb(128,128,128)' }" />
                    </svg>
                    <div>{{queryTypeText}}</div>
                </div>
                <div class="center">
                    <h1>Daten erfassen zu {{interneReferenz}}</h1>
                </div>
                <div class="right">
                    <div>
                        <IconWidget iconName="all_inbox" />
                        {{aktuellerKarton}}
                    </div>
                </div>
            </div>
        </template>   
        <div class="body">
            <div class="form-group">
                <label>ID:</label>
                <input type="text"  
                    ref="id_input"
                    v-model="aktuelleId" 
                    v-on:keyup.enter="queryData" />       
            </div>  
            <div class="form-group">
                <label>Anzahl:</label>
                <input type="number" 
                    v-model="aktuelleAnzahl"
                    v-on:keyup.enter="queryData" />       
            </div>
            <div class="form-group" v-if="abfrageAktiv">
                <h3>Abfrage läuft ...</h3>
            </div>
            <div class="form-group" v-if="!abfrageAktiv && abfrageErgebnis">
                <label>Ergebnis der letzten Abfrage:</label>
                <div v-if="abfrageErgebnis.anzahlKlasseNichtGefunden == 0">
                    <div>
                        ASIN: <span>{{abfrageErgebnis.ergebnisAsin}}</span>
                    </div>
                    <div>
                        Titel: <span>{{abfrageErgebnis.ergebnisTitel}}</span>
                    </div>
                    <div>
                        max. EK: <span>{{ numericFormat(abfrageErgebnis.ergebnisMaxEk, {numDecimalPlaces: 2, showThousandSeperator: true})}}</span>
                    </div>
                    <div>
                        Rang: <span>{{ numericFormat(abfrageErgebnis.ergebnisRang, {numDecimalPlaces: 0, showThousandSeperator: true})}}</span>
                    </div>
                    <div class="ampel">
                        <div class="green" v-if="abfrageErgebnis.anzahlKlasseGruen > 0">
                            <div>{{abfrageErgebnis.anzahlKlasseGruen}}</div>
                        </div>
                        <div class="yellow" v-if="abfrageErgebnis.anzahlKlasseGelb > 0">
                            <div>{{abfrageErgebnis.anzahlKlasseGelb}}</div>
                        </div>
                        <div class="red" v-if="abfrageErgebnis.anzahlKlasseRot > 0">
                            <div>{{abfrageErgebnis.anzahlKlasseRot}}</div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <h2>keine Daten gefunden</h2>
                </div>
            </div>
        </div>  
        <template v-slot:footer>
            <ButtonWidget 
                iconName="arrow_left" 
                text="zurück zum Einstieg" 
                @click="$router.push('/home')"/> 
            <ButtonWidget 
                iconName="all_inbox" 
                text="Kartoninhalt anzeigen" 
                @click="$router.push('/historie/' + vorgangsId)" /> 
            <ButtonWidget 
                iconName="all_inbox" 
                :text="'Karton ' + aktuellerKarton + ' schließen'" 
                @click="kartonSchliessen"
                :disabled="!canKartonSchliessen" />   
            <ButtonWidget 
                iconName="arrow_right" 
                text="Kompletten Auftrag beenden" 
                @click="$router.push('/abschluss/' + vorgangsId)" />               
        </template>
        
        <AskModal 
            ref="ask-zustand-modal" />
            
    </BaseView>
</template>

<style lang="scss">
    div.header {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr auto 1fr;
        cursor: pointer;
        > .left {
            display: grid;
            grid-template-columns: auto auto;
            justify-content: start;
            align-items: center;
            > svg {
                align-self: center; 
                margin: 4px;
            }
        }
        > .center {
            display: grid;
        }
        > .right {
            display: grid;
            justify-content: end;
            > div {
                align-self: center; 
                text-align: center;
                display: grid;
                align-content: center;
                margin: 4px;
                width: 48px;
                height: 48px;
                position: relative;
                font-size: 36px;
                font-weight: bold;
                z-index: 0;
                > svg {
                    position: absolute;
                    left: 4px;
                    top: 4px;
                    width: 40px;
                    height: 40px;
                    fill: rgba(255,255,255,0.5);
                    z-index: -1;
                }
            }
        }
    }
    div.ampel {
        display: flex;
        > div {
            flex: 1 1 auto;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;    
            color: white;
            font-size: 48px;        
        }
        .red {
            background-color: red;
        }
        .yellow {
            background-color: yellow;
        }
        .green {
            background-color: green;
        }
    }
</style>

<script>
    
    import { ampelService } from '@/services/ampelService';
    import { ampelLog } from "@/services/ampelLog";
    import { numericFormat } from '@/util/filter';
    import BaseView from "../base-view.vue";
    import ButtonWidget from "../../widgets/button-widget.vue";
    import IconWidget from "../../widgets/icon-widget.vue";
    import AskModal from "./erfassung-view-ask-zustand-modal.vue";
    
    import { ZebraClient, Printer } from "@/clients/zebra/zebra-client";
    import fnSkuTemplate from "@/clients/zebra/labelTemplateFnSku.zpl?raw";
    const zebraClient = new ZebraClient();

    export default {
        components: {
            BaseView,
            ButtonWidget,
            IconWidget,
            AskModal
        },
        props: {
            vorgangsId: {
                type: String,
                required: true
            }
        },
        data: function() {
            return {
                versandOption: null,
                interneReferenz: null,
                queryType: null,
                aktuellerKarton: null,
                aktuelleId: null,
                aktuelleAnzahl: null,
                aktuellerKartonLeer: true,
                abfrageErgebnis: null,
                abfrageAktiv: false,
                fbaInboundPrintLabel: false,
                identifierType: null
            }
        }, 
        computed: {
            canQueryData: function() {
                if (!this.aktuellerKarton)
                    return false;
                if (!this.aktuelleId)
                    return false;
                if (!this.aktuelleAnzahl)
                    return false;
                return true;
            },
            canKartonSchliessen: function() {
                return !this.aktuellerKartonLeer;
            },
            queryTypeText() {
                switch (this.queryType) {
                    case 'USED': 
                        return "gebr";
                    case 'NEW': 
                        return "neu";
                    default: 
                        return "???";
                }
            }
        },        
        methods: {
            numericFormat,
            init: async function() {
                await ampelService
                    .leseKartonStatistik({
                        vorgangsId: this.vorgangsId
                    })
                    .then(result => {
                        this.versandOption = result.vorgangsVersandOption;
                        this.interneReferenz = result.interneReferenz;
                        this.queryType = result.queryType;
                        this.identifierType = result.identifierType;
                        this.fbaInboundPrintLabel = result.fbaInboundPrintLabel;
                        let kartonNummern = result.kartons.map(x => x.kartonNummer);
                        if (kartonNummern.length > 0) {
                            this.aktuellerKartonLeer = false;
                            this.aktuellerKarton = Math.max(...kartonNummern);
                        } else {
                            this.aktuellerKartonLeer = true;
                            this.aktuellerKarton = 1;
                        }
                    });
                this.aktuelleId = '';
                this.aktuelleAnzahl = 1;                    
                this.$refs.id_input.focus();
            },
            queryData: async function() {
                if (this.canQueryData == false) {
                    this.$refs.id_input.focus();
                    return;
                } else {
                    this.abfrageErgebnis = null;
                    this.abfrageAktiv = true;
                    let skipQuery = false;

                    // #214
                    // Wenn die Eingabe weniger als 10 Stellen oder genau 11 oder genau 12 Stellen hat,
                    // dann EAN und ISBN mit 2x auffüllen um 13 Stellen zu erreichen 
                    const identifierType = this.identifierType;
                    if ((identifierType == 'EAN') || (identifierType == 'ISBN')) {
                        const aktuelleIdLength = this.aktuelleId.length;
                        if ((aktuelleIdLength < 10) || (aktuelleIdLength == 11) || (aktuelleIdLength == 12)) {
                            this.aktuelleId = this.aktuelleId.padStart(13, "2000000000000");
                        }
                    }

                    if (this.queryType == 'USED')  {
                        await ampelService
                            .vorverarbeiteEingabe({
                                vorgangsId: this.vorgangsId,
                                id: this.aktuelleId
                            })
                            .then(async result => { 
                                if (result.vlbFixedPrice === false) {
                                    await this.$refs['ask-zustand-modal']
                                        .showModal()
                                        .then(choice => {
                                            skipQuery = !choice; 
                                        })
                                }
                            });
                    }
                    if (skipQuery === false) {
                        await ampelService
                            .verarbeiteEingabe({
                                vorgangsId: this.vorgangsId,
                                id: this.aktuelleId,
                                anzahl: this.aktuelleAnzahl,
                                kartonNummer: this.aktuellerKarton
                            })
                            .then(result => {
                                if (result?.errorMessage) {
                                    ampelLog.push({
                                        level: 'ERROR',
                                        path: `api/v2/ampel/verarbeiteEingabe`,
                                        error: {
                                            status: 500, 
                                            statusText: result.errorMessage 
                                        }
                                    });
                                } 
                                this.abfrageErgebnis = result;
                                if (result?.anzahlKlasseGruen > 0)
                                    this.aktuellerKartonLeer = false;
                            });
                        if (this.fbaInboundPrintLabel) {
                            if (this.abfrageErgebnis?.anzahlKlasseGruen > 0) {
                                let fnSku = this.abfrageErgebnis?.extItemId2;
                                let numLabels = this.abfrageErgebnis?.anzahlKlasseGruen;
                                await this.printLabel(fnSku, numLabels);
                            }
                        }
                    }
                    this.abfrageAktiv = false;
                    this.aktuelleId = '';
                    this.aktuelleAnzahl = 1;
                    this.$refs.id_input.focus();
                }
            },
            printLabel: async function(fnSku, numLabels) {
                
                // Parameter prüfen
                if (!fnSku) {
                    alert("FNSKU konnte nicht ermittelt werden. Artikel zur Seite legen!");
                    return;
                }

                // Drucker suchen (evtl. gecached)
                if (!Array.isArray(this._availablePrinter)) {
                    console.log("Drucker nicht gecached ...")
                    try {
                        console.log("Suche Drucker ...")
                        this._availablePrinter = await zebraClient.getAvailablePrinter();
                        console.log("Suche Drucker abgeschloßen", this._availablePrinter)
                    }
                    catch (e) {
                        alert("Fehler beim Suchen der Drucker.");
                        console.error(e);
                        this._availablePrinter = null;
                    }
                }

                // Ersten Drucker wählen
                const selectedPrinter  = this._availablePrinter?.[0];
                console.log("Drucker ausgewählt aus Cache", selectedPrinter)
                if (!selectedPrinter) {
                    alert("Drucker nicht gefunden.")
                    return;
                }

                // Drucken
                try {
                    const zplDokument = fnSkuTemplate.replaceAll("{{FNSKU}}", fnSku);
                    for (let i=0; i<numLabels; i++) {
                        await zebraClient.write(selectedPrinter, zplDokument);
                    }
                } catch (e) {
                    alert("Fehler beim Drucken. Abbruch.")
                    console.error(e);
                    return;                    
                }

            },
            kartonSchliessen: function() {
                if (!this.canKartonSchliessen) 
                    return;
                let x = Math.max(1, this.aktuellerKarton);
                this.aktuellerKarton = x + 1;
                this.aktuellerKartonLeer = true;
                this.abfrageErgebnis = null;
                this.aktuelleId = '';
                this.aktuelleAnzahl = 1;
                this.$refs.id_input.focus();
            }
        }, 
        watch: {
            vorgangsId: function() {
                this.init();
            } 
        },
        mounted: function() {
            this.init(); 
        }
    }
</script>